<template>
    <div>
        <v-navigation-drawer
            app
            clipped
            permanent
          >
            <v-list-item 
                color="blue-grey lighten-4"
                style="padding: 20px 0px !important;"
            >
              <v-list-item-content>
                  <v-list-item-title class="text-center text-h5 font-weight-bold">
                      Popular <br> search examples
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- <v-divider></v-divider> -->
            <v-list
            dense
            shaped
            >
                

                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                    mandatory
                    @change="changeContent"
                >
                    <v-list-item
                    v-for="item in items"
                    :key="item"
                    link
                    >
                    <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid>
                <v-card outlined>
                    <v-card-title class="text-h5 font-weight-bold mb-2">{{ curEx.title }}</v-card-title>
                    <v-card-subtitle class="text-subtitle-2" style="line-height: 1.2em;">{{ curEx.desc }}</v-card-subtitle> 
                </v-card>
                <v-divider style="margin: 10px 0px;"></v-divider>
                <div class="d-flex justify-center">

                    <v-simple-table style="width: 90%">
                         <template v-slot:default>
                         <tbody>
                             <tr
                             v-for="(item, idx) in curEx.ex"
                             :key="idx"
                             >
                             <td class="text-body-1">{{ item.search  }}</td>
                             <td>{{ item.desc }}</td>
                             </tr>
                         </tbody>
                         </template>
                     </v-simple-table>
                </div>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import exJSON from '@/components/search/searchHelp.json'

export default {
    name: 'searchHelp',
    data(){
        return {
            items: [
                'FTP Service',
                'LDAP Service',
                'Vulnerable LDAP Service',
                'DBMS Service',
                'Vulnerable DBMS Service',
                'Services at risk with external access',
                'Vulnerable database, contents',
                'Vulnerable IP Camera',
                'Devices with exposed files'
            ],
            selectedItem:0,
            exJson: exJSON,
            curEx:null,
        }
    },
    created(){
        this.curEx=this.exJson.examples[0]
    },
    methods:{
        changeContent:function(){
            this.curEx=this.exJson.examples[this.selectedItem];
        }
    }
}
</script>

<style scoped>
.v-list--dense .v-subheader{
    height: 20px;
}
.v-list .v-list-item--active{
    color: white !important;
    background-color:#90A4AE !important;
}
.v-list-item--active .v-list-item__content .v-list-item__title{
    font-size: 13px !important;
}
</style>