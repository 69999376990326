// import IPCIDR from "ip-cidr";
// import { hostname } from "os";

// import IPCIDR from "ip-cidr";

// import { hostname } from "os";

export const statusChartQuery = function (starttime, endtime, field) {
  return {
    rightchart: "true",
    timestamp: [starttime, endtime],
    field: field,
  };
};

export const backupLogSearch = function (starttime, endtime) {
  return {
    timestamp: [starttime, endtime],
    flag: "backup",
  };
};

export const updateLogSearch = function (starttime, endtime) {
  return {
    timestamp: [starttime, endtime],
    flag: "update",
  };
};

export const scanScrollQuery = function (scrollId) {
  return {
    scroll_id: scrollId,
  };
};

export const scanScrollQuery2 = function () {
  return {
    firestScroll: "true",
  };
};

export const runningCycleQuery = {
  runningCycleQuery: "true",
};
export const baseCycleQuery2 = function (cycle) {
  return {
    baseCycleQuery: cycle,
  };
};

export const baseCycleQuery1 = function (cycle) {
  return {
    baseCycleQuery: cycle,
    types: "pieAggs",
  };
};

export const baseCycleQuery = function (cycle) {
  return {
    baseCycleQuery: cycle,
    types: "currentRiskAggs",
  };
};

export const baseBCycleQuery = function (bcycle) {
  return {
    baseCycleQuery: bcycle,
    types: "currentRiskAggs",
  };
};

export const timelineChartQuery = function (selection, starttime, endtime) {
  return {
    chart: [selection, starttime, endtime],
  };
};

export const timelineClickBarQuery = function (selection, starttime, endtime) {
  return {
    clickOnBar: [selection, starttime, endtime],
  };
};

export const riskMapQuery1 = function (keyword, starttime, endtime) {
  return {
    map: [keyword, starttime, endtime],
  };
};

export const riskMapQuery2 = function (selection, starttime, endtime) {
  return {
    showContext: [selection, starttime, endtime],
  };
};

export const riskMapCustomQuery = function (
  countryname,
  starttime,
  endtime,
  selection
) {
  return {
    customQuery: [countryname, starttime, endtime, selection],
  };
};

export const analysisQuery1 = function (
  analysisField2,
  analysisField1,
  finishedCycle,
  starttime,
  endtime
) {
  return {
    blacklisted: analysisField2,
    anl_vip: "true",
    cycle_num: finishedCycle,
    total: analysisField1,
    threat: analysisField1,
    vuln: analysisField1,
    timestamp: [starttime, endtime],
  };
};

export const analysisQuery2 = function (
  analysisField1,
  finishedCycle,
  starttime,
  endtime
) {
  return {
    anl_vip: "true",
    cycle_num: finishedCycle,
    total: analysisField1,
    threat: analysisField1,
    vuln: analysisField1,
    timestamp: [starttime, endtime],
  };
};

export const analysisField1 = function (field) {
  switch (field.operator) {
    case "lt":
      return {
        range: {
          [field.elk]: {
            lt: field.value,
          },
        },
      };
    case "lte":
      return {
        range: {
          [field.elk]: {
            lte: field.value,
          },
        },
      };
    case "eq":
      return;
    case "gte":
      return {
        range: {
          [field.elk]: {
            gte: field.value,
          },
        },
      };
    case "gt":
      return {
        range: {
          [field.elk]: {
            gt: field.value,
          },
        },
      };
    default:
      return false;
  }
};

export const analysisField2 = function (field) {
  switch (field.operator) {
    case "false":
      return {
        match_phrase: {
          [field.elk]: "false",
        },
      };
    case "true":
      return {
        match_phrase: {
          [field.elk]: "true",
        },
      };
    case "any":
      return {
        exists: {
          field: field.elk,
        },
      };
    case "=":
      return false;
    case "!=":
      return false;
    default:
      return false;
  }
};

export const settingQuery = function () {
  return {
    refresh: [
      "EK",
      "scan-client1",
      "scan-client2",
      "scan-client3",
      "scan-server",
      "scan-client4",
    ],
  };
};

export const settingQuery2 = function (server) {
  return {
    server: server,
  };
};

export const settingChartQuery = function (server, starttime, endtime, field) {
  return {
    server: server,
    timestamp: [starttime, endtime],
    field: field,
  };
};

export const configurationScanQuery = function () {
  return {
    "now-scanning-ip": "true",
  };
};

export const configurationScanQuery2 = function (
  starttime,
  endtime,
  scanTarget
) {
  return {
    "scanned-ip": "true",
    timestamp: [starttime, endtime],
    select: scanTarget,
    // "searchafterflag" : "on",
    // "searchafter" : searchAfter
  };
};

export const configurationScanQuery1 = function (
  starttime,
  endtime,
  scanTarget,
  searchAfter
) {
  return {
    "scanned-ip": "true",
    timestamp: [starttime, endtime],
    select: scanTarget,
    searchafterflag: "on",
    searchafter: searchAfter,
  };
};

export const configurationScanQuery3 = function (segmentsA, itip, dotsInItemB) {
  return {
    fetchSegments: [segmentsA, itip, dotsInItemB],
  };
};

export const topDevices = function (field) {
  const query = {
    topdevices: field,
  };
  return query;
};

// export const searchMain = function (field) {
//   const query = {
//     search_main: field,
//   };
//   return query;
// };

export const searchCommandKeyword = function (order) {
  return {
    page: order,
  };
};

export const searchCommandKeywordScroll = function (order, searchAfter) {
  return {
    page: order,
    searchafterflag: "on",
    searchafter: searchAfter,
  };
};

export const searchAfterScrollQuery = function (order, searchAfter) {
  return {
    page: order,
    searchafterflag: "on",
    searchafter: searchAfter,
  };
};

export const searchCommand = function (order) {
  return {
    page: order,
  };
};

export const searchKeyword = {
  ip: {
    type: `simple`,
    help: `ip:[IP address]`,
    params: [{ name: `ip`, required: true }],
  },
  hostname: {
    type: `simple`,
    help: `host:[IP address]`,
    params: [{ name: `hostname`, required: true }],
  },
  domain: {
    type: `simple`,
    help: `host:[IP address]`,
    params: [{ name: `domain`, required: true }],
  },
  country: {
    type: `simple`,
    params: [{ name: `country`, required: true }],
  },
  city: {
    type: `simple`,
    params: [{ name: `city`, required: true }],
  },
  banner: {
    type: `simple`,
    params: [{ name: `banner`, required: true }],
  },
  cookie: {
    type: `simple`,
    params: [{ name: `cookie`, required: true }],
  },
  files: {
    type: `simple`,
    params: [{ name: `files`, required: true }],
  },
  httptitle: {
    type: `simple`,
    params: [{ name: `httptitle`, required: true }],
  },
  smb_dnsdomain: {
    type: `simple`,
    params: [{ name: `smb_dnsdomain`, required: true }],
  },
  smb_fqdn: {
    type: `simple`,
    params: [{ name: `smb_fqdn`, required: true }],
  },
  os: {
    type: `simple`,
    params: [{ name: `os`, required: true }],
  },
  devtype: {
    type: `simple`,
    params: [{ name: `devtype`, required: true }],
  },
  cpe: {
    type: `simple`,
    params: [{ name: `cpe`, required: true }],
  },
  webfiles: {
    type: `simple`,
    params: [{ name: `webfiles`, required: true }],
  },
  scantime: {
    type: `simple`,
    params: [{ name: `scantime`, required: true }],
  },
  tcategory: {
    type: `simple`,
    params: [{ name: `tcategory`, required: true }],
  },
  tdetect: {
    type: `simple`,
    params: [{ name: `tdetect`, required: true }],
  },
  cve: {
    type: `simple`,
    params: [{ name: `cve`, required: true }],
  },
  tcp: {
    type: `simple`,
    params: [{ name: `tcp`, required: true }],
  },
  cycle: {
    type: `simple`,
    params: [{ name: `cycle`, required: true }],
  },
  asnum: {
    type: `simple`,
    params: [{ name: `asnum`, required: true }],
  },
  asname: {
    type: `simple`,
    params: [{ name: `asname`, required: true }],
  },
  tsource: {
    type: `simple`,
    params: [{ name: `tsource`, required: true }],
  },
  date: {
    type: `simple`,
    params: [{ name: `date`, required: true }],
  },
  net: {
    type: `simple`,
    params: [{ name: `net`, required: true }],
  },
  tcurrentblack: {
    type: `noparam`,
  },
  anonftp: {
    type: `noparam`,
  },
  anonldap: {
    type: `noparam`,
  },
  httphdr: {
    type: `noparam`,
  },
  owa: {
    type: `noparam`,
  },
  phpmyadmin: {
    type: `noparam`,
  },
  x11open: {
    type: `noparam`,
  },
  x11srv: {
    type: `noparam`,
  },
  netdev: {
    type: `noparam`,
  },
  phonedev: {
    type: `noparam`,
  },
  openport: {
    type: `noparam`,
  },
  icsdevice: {
    type: `noparam`,
  },
  govdomain: {
    type: `noparam`,
  },
  vipdevice: {
    type: `noparam`,
  },
  tblack: {
    type: `noparam`,
  },
  afp: {
    type: `noparam`,
  },
  ajp: {
    type: `noparam`,
  },
  bitcoin: {
    type: `noparam`,
  },
  cics: {
    type: `noparam`,
  },
  citrix: {
    type: `noparam`,
  },
  dns: {
    type: `noparam`,
  },
  ftp: {
    type: `noparam`,
  },
  hadoop: {
    type: `noparam`,
  },
  hbase: {
    type: `noparam`,
  },
  http: {
    type: `noparam`,
  },
  ldap: {
    type: `noparam`,
  },
  mssql: {
    type: `noparam`,
  },
  db2: {
    type: `noparam`,
  },
  mongodb: {
    type: `noparam`,
  },
  mysql: {
    type: `noparam`,
  },
  oracle: {
    type: `noparam`,
  },
  redis: {
    type: `noparam`,
  },
  vnc: {
    type: `noparam`,
  },

  scorelevel: {
    type: `simple`,
    params: [{ name: `scorelevel`, required: true }],
  },
  scorepoint: {
    type: `simple`,
    params: [{ name: `scorepoint `, required: true }],
  },
  vscorelevel: {
    type: `simple`,
    params: [{ name: `vscore `, required: true }],
  },
  vscorepoint: {
    type: `simple`,
    params: [{ name: `vscorepoint `, required: true }],
  },
  tscorelevel: {
    type: `simple`,
    params: [{ name: `tscore `, required: true }],
  },
  tscorepoint: {
    type: `simple`,
    params: [{ name: `tscorepoint `, required: true }],
  },
  service: {
    type: `simple`,
    params: [
      { name: `servicename`, required: true },
      { name: `serviceport`, required: false },
    ],
  },
  product: {
    type: `simple`,
    params: [
      { name: `servicename`, required: true },
      { name: `productname`, required: true },
      { name: `serviceport`, required: false },
    ],
  },
  version: {
    type: `simple`,
    params: [
      { name: `servicename`, required: true },
      { name: `productname`, required: true },
      { name: `serviceversion`, required: true },
      { name: `serviceport`, required: false },
    ],
  },
  script: {
    type: `simple`,
    params: [
      { name: `scriptid`, required: true },
      { name: `scriptoutput`, required: false },
    ],
  },
};

export const searchTemplates = {
  commands: searchKeyword,
};

// export const search = {
//   host: host,
//   hostname: hostname,
//   domain: domain,
//   country: country,
//   city: city
// }

// export const searchTemplates = {
//   commands: commands
// }

// export const searchScrollQuery = function(order, filters, searchAfter){
//   return {
//     order,filters, searchAfter
//   }

// }

// export const searchQuery = function(){
//   return{
//     "host" : ip,
//     "hostname" : hostname,
//     "domain" : domain,
//     "country" : country,
//     "city" : city

//   }
// };

// export const currentRiskAggs = {
//   "total_median": {
//     "percentiles": {
//       "field": "score.total_score",
//       "percents": [
//         95
//       ]
//     }
//   },
//   "threat_median": {
//     "percentiles": {
//       "field": "score.threat_score",
//       "percents": [
//         95
//       ]
//     }
//   },
//   "vuln_median": {
//     "percentiles": {
//       "field": "score.vuln_score",
//       "percents": [
//         95
//       ]
//     }
//   },
//   "total_stats": {
//     "extended_stats": {
//       "field": "score.total_score"
//     }
//   },
//   "threat_stats": {
//     "extended_stats": {
//       "field": "score.threat_score"
//     }
//   },
//   "vuln_stats": {
//     "extended_stats": {
//       "field": "score.vuln_score"
//     }
//   }
// };

// export const cycleAggs = {
//   "os": {
//     "terms": {
//       "field": "xml-nmap.host.os.osmatch.osclass.osfamily.keyword",
//       "size": 25
//     }
//   },
//   "port": {
//     "terms": {
//       "field": "xml-nmap.host.ports.port.portid.keyword",
//       "size": 25
//     }
//   },
//   "port_status": {
//     "terms": {
//       "field": "xml-nmap.host.ports.port.state.state.keyword"
//     }
//   },
//   "up_device": {
//     "terms": {
//       "field": "xml-nmap.host.status.state.keyword"
//     }
//   },
//   "product": {
//     "terms": {
//       "field": "xml-nmap.host.ports.port.service.product.keyword",
//       "size": 25
//     }
//   },
//   "autonomous": {
//     "terms": {
//       "field": "geoip.as_org.keyword",
//       "size": 25
//     }
//   },
//   "autonomous_count":{
//     "cardinality": {
//       "field": "geoip.as_org.keyword"
//     }
//   },
//   "host_count": {
//     "cardinality": {
//       "field": "geoip.ip"
//     }
//   },
//   "avg_score":{
//     "avg": {
//       "field": "score.total_score"
//     }
//   },
//   "avg_vuln_score":{
//     "avg": {
//       "field": "score.vuln_score"
//     }
//   },
//   "avg_threat_score":{
//     "avg": {
//       "field": "score.threat_score"
//     }
//   },
//   "host_scores": {
//     "range": {
//       "field": "score.total_score",
//       "ranges": [
//         {
//           "key": "all",
//           "from": -1
//         },
//         {
//           "key": "critical",
//           "from": 9,
//           "to": 10
//         },
//         {
//           "key": "high",
//           "from": 7,
//           "to": 8.99
//         },
//         {
//           "key": "medium",
//           "from": 4,
//           "to": 6.99
//         },
//         {
//           "key": "low",
//           "from": 0,
//           "to": 3.99
//         }
//       ]
//     }
//   },
//   "threat_scores": {
//     "range": {
//       "field": "score.threat_score",
//       "ranges": [
//         {
//           "key": "all",
//           "from": -1
//         },
//         {
//           "key": "critical",
//           "from": 9,
//           "to": 10
//         },
//         {
//           "key": "high",
//           "from": 7,
//           "to": 8.99
//         },
//         {
//           "key": "medium",
//           "from": 4,
//           "to": 6.99
//         },
//         {
//           "key": "low",
//           "from": 0,
//           "to": 3.99
//         }
//       ]
//     }
//   },
//   "vuln_scores": {
//     "range": {
//       "field": "score.vuln_score",
//       "ranges": [
//         {
//           "key": "all",
//           "from": -1
//         },
//         {
//           "key": "critical",
//           "from": 9,
//           "to": 10
//         },
//         {
//           "key": "high",
//           "from": 7,
//           "to": 8.99
//         },
//         {
//           "key": "medium",
//           "from": 4,
//           "to": 6.99
//         },
//         {
//           "key": "low",
//           "from": 0,
//           "to": 3.99
//         }
//       ]
//     }
//   }
// };

// export const getOpenPortsByCycle = function(cycle){
//   return{
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [],
//         "filter": [
//           {
//             "bool": {
//               "filter": [
//                 {
//                   "bool": {
//                     "should": [
//                       {
//                         "match_phrase": {
//                           "xml-nmap.host.ports.port.state.state": "open"
//                         }
//                       }
//                     ],
//                     "minimum_should_match": 1
//                   }
//                 },
//                 {
//                   "multi_match": {
//                     "type": "best_fields",
//                     "query": "xml-nmap.cycle = "+cycle,
//                     "lenient": true
//                   }
//                 }
//               ]
//             }
//           }
//         ]
//       }
//     }
//   }
// };

// // Queries used in Risk Timeline Home page
// const rtAggs = {
//   "over_time": {
//     "date_histogram": {
//       "field": "@timestamp",
//       "calendar_interval": "day",
//       "order": {
//         "_key": "asc"
//       }
//     },
//     "aggs": {
//       "host_scores": {
//         "range": {
//           "field": "xml-nmap.total_score",
//           "ranges": [
//             {
//             "key": "all",
//             "from": -1
//             },
//             {
//             "key": "critical",
//             "from": 9,
//             "to": 10
//             },
//             {
//             "key": "high",
//             "from": 7,
//             "to": 8.99
//             },
//             {
//             "key": "medium",
//             "from": 4,
//             "to": 6.99
//             },
//             {
//             "key": "low",
//             "from": 0,
//             "to": 3.99
//             }
//           ]
//         }
//       }
//     }
//   }
// };

// const scriptScoresAggs = {
//   "over_time": {
//     "date_histogram": {
//       "field": "@timestamp",
//       "calendar_interval": "day",
//       "order": {
//         "_key": "asc"
//       }
//     },
//     "aggs": {
//       "host_scores": {
//         "range": {
//           "field": "score.nse_score.portscript_score.score",
//           "ranges": [
//             {
//             "key": "all",
//             "from": -1
//             },
//             {
//             "key": "critical",
//             "from": 9,
//             "to": 10
//             },
//             {
//             "key": "high",
//             "from": 7,
//             "to": 8.99
//             },
//             {
//             "key": "medium",
//             "from": 4,
//             "to": 6.99
//             },
//             {
//             "key": "low",
//             "from": 0,
//             "to": 3.99
//             }
//           ]
//         }
//       }
//     }
//   }
// };

// const rtUpDevices = function(startDate, endDate){
// return{
//   "size": 0,
//   "_source":["ipaddr", "score.total_score"],
//   "query": {
//     "bool": {
//       "must": [],
//       "filter": [
//         {
//           "bool": {
//             "should": [
//               {
//                 "match_phrase": {
//                   "xml-nmap.host.status.state": "up"
//                 }
//               }
//             ],
//             "minimum_should_match": 1
//           }
//         },
//         {
//           "range": {
//             "@timestamp": {
//               "format": "strict_date_optional_time",
//               "gte": startDate,
//               "lte": endDate
//             }
//           }
//         }
//       ]
//     }
//   },
//   "aggs": rtAggs
// }
// };

// const rtOpenDevices = function(startDate, endDate){
// return{
//   "size": 0,
//   "_source":["ipaddr", "score.total_score"],
//   "query": {
//     "bool": {
//       "must": [],
//       "filter": [
//         {
//           "bool": {
//             "should": [
//               {
//                 "match_phrase": {
//                   "xml-nmap.host.ports.port.state.state" : "open"
//                 }
//               }
//             ],
//             "minimum_should_match": 1
//           }
//         },
//         {
//           "range": {
//             "@timestamp": {
//               "format": "strict_date_optional_time",
//               "gte": startDate,
//               "lte": endDate
//             }
//           }
//         }
//       ]
//     }
//   },
//   "aggs": rtAggs
// }
// };

// const rtVulnDevices = function(startDate, endDate){
// return {
//   "size": 0,
//   "_source":["ipaddr", "score.total_score"],
//   "query": {
//     "bool": {
//       "must": [],
//       "filter": [
//         {
//           "bool": {
//             "should": [
//               {
//                 "range": {
//                   "score.total_score": {
//                     "gte": 5
//                   }
//                 }
//               }
//             ],
//             "minimum_should_match": 1
//           }
//         },
//         {
//           "range": {
//             "@timestamp": {
//               "format": "strict_date_optional_time",
//               "gte": startDate,
//               "lte": endDate
//             }
//           }
//         }
//       ]
//     }
//   },
//   "aggs": {
//     "over_time": {
//       "date_histogram": {
//         "field": "@timestamp",
//         "calendar_interval": "day",
//         "order": {
//           "_key": "asc"
//         }
//       },
//       "aggs": {
//         "host_scores": {
//           "range": {
//             "field": "score.vuln_score",
//             "ranges": [
//               {
//               "key": "all",
//               "from": -1
//               },
//               {
//               "key": "critical",
//               "from": 9,
//               "to": 10
//               },
//               {
//               "key": "high",
//               "from": 7,
//               "to": 8.99
//               },
//               {
//               "key": "medium",
//               "from": 4,
//               "to": 6.99
//               },
//               {
//               "key": "low",
//               "from": 0,
//               "to": 3.99
//               }
//             ]
//           }
//         }
//       }
//     }
//   }
// }
// };

// const rtThreatDevices = function(startDate, endDate){
// return {
//   "size": 0,
//   "query": {
//     "bool": {
//       "must": [],
//       "filter": [
//         {
//           "bool": {
//             "should": [
//               {
//                 "range": {
//                   "score.threat_score": {
//                     "gte": 7
//                   }
//                 }
//               }
//             ],
//             "minimum_should_match": 1
//           }
//         },
//         {
//           "range": {
//             "@timestamp": {
//               "format": "strict_date_optional_time",
//               "gte": startDate,
//               "lte": endDate
//             }
//           }
//         }
//       ]
//     }
//   },
//   "aggs": {
//     "over_time": {
//       "date_histogram": {
//         "field": "@timestamp",
//         "calendar_interval": "day",
//         "order": {
//           "_key": "asc"
//         }
//       },
//       "aggs": {
//         "host_scores": {
//           "range": {
//             "field": "score.threat_score",
//             "ranges": [
//               {
//               "key": "all",
//               "from": -1
//               },
//               {
//               "key": "critical",
//               "from": 9,
//               "to": 10
//               },
//               {
//               "key": "high",
//               "from": 7,
//               "to": 8.99
//               },
//               {
//               "key": "medium",
//               "from": 4,
//               "to": 6.99
//               },
//               {
//               "key": "low",
//               "from": 0,
//               "to": 3.99
//               }
//             ]
//           }
//         }
//       }
//     }
//   }
// }
// };

// export const riskTimelineQueries = {
//   rtUpDevices: rtUpDevices,
//   rtOpenDevices: rtOpenDevices,
//   rtThreatDevices: rtThreatDevices,
//   rtVulnDevices: rtVulnDevices,
//   customAggs: scriptScoresAggs
// }

// // Aggreagtions used in the map sections (groups information according to city)
// const mapAggregations = {
//     "city_totals": {
//         "terms": {
//           "field": "geoip.city_name.keyword",
//           "size": 500
//         },
//         "aggs": {
//           "coordinates": {
//             "top_hits": {
//               "sort": [{"xml-nmap.host.starttime.keyword": {"order" : "desc"}}],
//               "size": 1,
//               "_source": {
//                 "includes": ["geoip.location", "geoip.country_code2", "geoip.country_name", "@timestamp", "xml-nmap.host.starttime", "xml-nmap.host.endtime"]
//               }
//             }
//           },
//           "median_total_score": {
//             "percentiles": {
//               "field": "score.total_score",
//               "percents": [50]
//             }
//           },
//           "median_threat_score":{
//             "percentiles": {
//               "field": "score.threat_score",
//               "percents": [50]
//             }
//           },
//           "median_vuln_score":{
//             "percentiles": {
//               "field": "score.vuln_score",
//               "percents": [50]
//             }
//           }
//         }
//     }
// };

// export const riskMapQueries = {
//     total: {
//         "size": 0,
//         "query":{
//           "bool": {
//             "filter": [
//               {
//                 "bool": {
//                 "should": [
//                     {
//                     "match_phrase": {
//                       "xml-nmap.host.status.state": "up"
//                     }
//                     }
//                 ],
//                 "minimum_should_match": 1
//                 }
//               }
//             ]
//           }
//         },
//         "aggs": mapAggregations
//     },
//     open: {
//       "size": 0,
//       "query":{
//         "bool": {
//             "filter": [
//               {
//                   "bool": {
//                   "should": [
//                       {
//                       "match_phrase": {
//                           "xml-nmap.host.ports.port.state.state.keyword": "open"
//                       }
//                       }
//                   ],
//                   "minimum_should_match": 1
//                   }
//               }
//             ]
//         }
//       },
//       "aggs": mapAggregations
//     },
//     vuln: {
//       "size": 0,
//       "query":{
//         "bool": {
//             "filter": [
//               {
//                 "bool": {
//                   "should": [
//                     {
//                       "range": {
//                         "score.vuln_score": {
//                           "gte": 7
//                         }
//                       }
//                     }
//                   ],
//                   "minimum_should_match": 1
//                 }
//               }
//             ]
//         }
//       },
//       "aggs": mapAggregations
//     },
//     threat: {
//       "size": 0,
//       "query":{
//         "bool": {
//             "filter": [
//               {
//                 "bool": {
//                   "should": [
//                     {
//                       "range": {
//                         "score.threat_score": {
//                           "gte": 7
//                         }
//                       }
//                     }
//                   ],
//                   "minimum_should_match": 1
//                 }
//               }
//             ]
//         }
//       },
//       "aggs": mapAggregations
//     },
//     script: ``
// }

// // Analysis queries
// const matchPhrase = function(field, phrase) {
//   return {
//     "bool": {
//       "filter": [
//         {
//           "bool": {
//             "should": [
//               {
//                 "match_phrase": {
//                   [field]: phrase
//                 }
//               }
//             ],
//             "minimum_should_match": 1
//           }
//         }
//       ]
//     }
//   }
// }

// const notMatchPhrase = function(field, phrase) {
//   return {
//     "bool": {
//       "must_not": {
//         "bool": {
//           "should": [
//             {
//               "match_phrase": {
//                 [field]: phrase
//               }
//             }
//           ],
//           "minimum_should_match": 1
//         }
//       }
//     }
//   }
// }

// const equal = function(field, number) {
//   return {
//     "bool": {
//       "should": [
//         {
//           "match": {
//             [field]: number
//           }
//         }
//       ],
//       "minimum_should_match": 1
//     }
//   }
// }

// const notEqual = function(field, number, operator) {
//   return {
//     "bool": {
//       "should": [
//         {
//           "range": {
//             [field]: {
//               [operator]: number
//             }
//           }
//         }
//       ],
//       "minimum_should_match": 1
//     }
//   }
// }

// const exist = function(field) {
//   return {
//     "bool": {
//       "should": [
//         {
//           "exists": {
//             "field": field
//           }
//         }
//       ],
//       "minimum_should_match": 1
//     }
//   }
// }

// const baseAnalysisQuery = function(startDate, endDate) {
//   return {
//     "_source": ["geoip", "score", "xml-nmap.host", "threat"],
//     "size": 1000,
//     "sort": [
//       {
//         "@timestamp": {
//           "order": "desc"
//         }
//       }
//     ],
//     "query": {
//       "bool": {
//         "must": [],
//         "filter": [
//           {
//             "range": {
//               "@timestamp": {
//                 "format": "strict_date_optional_time",
//                 "gte": startDate,
//                 "lte": endDate
//               }
//             }
//           }
//         ],
//         "should": [],
//         "must_not": []
//       }
//     }
//   }
// }

// const groupedAggregations = function(field){
//   return {
//     "result": {
//       "terms": {
//         "field": field,
//         "size": 500
//       },
//       "aggs": {
//         "median_total_score": {
//           "percentiles": {
//             "field": "score.total_score",
//             "percents": [50]
//           }
//         },
//         "median_threat_score":{
//           "percentiles": {
//             "field": "score.threat_score",
//             "percents": [50]
//           }
//         },
//         "median_vuln_score":{
//           "percentiles": {
//             "field": "score.vuln_score",
//             "percents": [50]
//           }
//         },
//         "avg_total_score": {
//           "avg": {
//             "field": "score.total_score"
//           }
//         },
//         "avg_threat_score": {
//           "avg": {
//             "field": "score.threat_score"
//           }
//         },
//         "avg_vuln_score": {
//           "avg": {
//             "field": "score.vuln_score"
//           }
//         }
//       }
//     }
//   }
// }

// export const analysisQueries = {
//   baseQuery: baseAnalysisQuery,
//   matchPhrase: matchPhrase,
//   notMatchPhrase: notMatchPhrase,
//   equal: equal,
//   notEqual: notEqual,
//   exist: exist,
//   groupedAggregations: groupedAggregations
// }
